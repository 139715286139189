import { JobLocation } from 'types/Job';
import { ImageWithAuthor } from 'types/Location';

import FileWorker from 'Utils/FileWorker';

export class LocationImage implements ImageWithAuthor {
  #isFile: boolean = false;
  #file: File | null = null;
  name: string = '';
  created_at: string;
  lat: number;
  lon: number;
  url: string;
  is_rejected: number = 0;
  file_type: { id: number; name: string; description: string } = { id: 0, name: '', description: '' };
  user?: { id: number; name: string };
  can_delete?: boolean;

  constructor(image: File | ImageWithAuthor) {
    this.url = new FileWorker(image).url;

    if (image instanceof File) {
      this.#isFile = true;
      this.#file = image;
      this.name = image.name;
      this.created_at = '';
      this.lat = 0;
      this.lon = 0;
      this.user = null;
      this.can_delete = false;
      return;
    }

    for (const key in image) {
      this[key] = image[key];
    }
  }

  get isFile() {
    return this.#isFile;
  }

  get FileInstance() {
    return this.#file;
  }

  get plainImage(): ImageWithAuthor {
    return {
      url: this.url,
      created_at: this.created_at,
      lat: this.lat,
      lon: this.lon,
      user: this.user,
      is_rejected: this.is_rejected,
      file_type: this.file_type,
    };
  }
}

export function fileToLocationImage(file: File) {
  return new LocationImage(file);
}

export function imageToLocationImage(image: ImageWithAuthor) {
  return new LocationImage(image);
}

export function imageConverter(locations: JobLocation[] = []) {
  return locations.map((loc) => ({ ...loc, images: loc.images.map(imageToLocationImage) }));
}

export function canDeleteImage({ created_at, can_delete }: LocationImage | ImageWithAuthor) {
  return can_delete || !created_at;
}
