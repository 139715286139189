enum AppPermissions {
  do_all = 'do_all',
  create_jobs_for_own_departments = 'create_jobs_for_own_departments',
  create_jobs_for_all_departments = 'create_jobs_for_all_departments',
  copy_job = 'copy_job',
  cancel_requested_jobs = 'cancel_requested_jobs',
  cancel_all_jobs = 'cancel_all_jobs',
  see_jobs_for_own_departments = 'see_jobs_for_own_departments',
  see_requested_jobs = 'see_requested_jobs',
  see_assigned_jobs = 'see_assigned_jobs',
  see_jobs_for_own_company = 'see_jobs_for_own_company',
  see_all_jobs = 'see_all_jobs',
  edit_all_jobs = 'edit_all_jobs',
  edit_cancelled_job = 'edit_cancelled_job',
  edit_job_info = 'edit_job_info',
  update_po = 'update_po',
  assign_workers = 'assign_workers',
  see_jobs_timesheets = 'see_jobs_timesheets',
  see_all_timesheets = 'see_all_timesheets',
  edit_timesheets = 'edit_timesheets',
  see_users_for_own_departments = 'see_users_for_own_departments',
  see_workers_from_own_company = 'see_workers_from_own_company',
  see_all_users = 'see_all_users',
  create_dispatchers = 'create_dispatchers',
  create_dispatcher_supervisors = 'create_dispatcher_supervisors',
  update_dispatchers = 'update_dispatchers',
  update_dispatcher_supervisors = 'update_dispatcher_supervisors',
  delete_dispatchers = 'delete_dispatchers',
  delete_dispatcher_supervisors = 'delete_dispatcher_supervisors',
  add_workers = 'add_workers',
  deactivate_workers = 'deactivate_workers',
  see_invoices = 'see_invoices',
  edit_invoices = 'edit_invoices',
  see_only_clocked_out_timesheets = 'see_only_clocked_out_timesheets',
  see_invoices_for_own_departments = 'see_invoices_for_own_departments',
  see_only_own_departments = 'see_only_own_departments',
  see_all_departments = 'see_all_departments',
  update_completed_job_start_time = 'update_completed_job_start_time',
  view_shifts = 'view_shifts',
  download_job = 'download_job',
  complete_job = 'complete_job',
  generate_payroll_report = 'generate_payroll_report',
  can_cancel_without_charging = 'can_cancel_without_charging',
  generate_daily_report = 'generate_daily_report',
  generate_weekly_report = 'generate_weekly_report',
  generate_metrics_report = 'generate_metrics_report',
  see_timesheet_history = 'see_timesheet_history',
  delete_timesheets = 'delete_timesheets',
  can_do_timesheet_action = 'can_do_timesheet_action',
  can_confirm_cancel = 'can_confirm_cancel',
  assign_workers_to_simultaneous_shifts = 'assign_workers_to_simultaneous_shifts',
  allow_reconstruct_job = 'allow_reconstruct_job',
  edit_imported_job = 'edit_imported_job',
  edit_imported_job_locations = 'edit_imported_job_locations',
  release_workers = 'release_workers',
  generate_daily_open_report = 'generate_daily_open_report',
  can_see_dispatch = 'can_see_dispatch',
  can_see_workers = 'can_see_workers',
  can_see_subcontractors = 'can_see_subcontractors',
  can_see_roles = 'can_see_roles',
  can_see_billing = 'can_see_billing',
  can_see_invoicing = 'can_see_invoicing',
  can_see_reports = 'can_see_reports',
  update_all_users = 'update_all_users',
  see_all_department_groups = 'see_all_department_groups',
  see_only_own_department_groups = 'see_only_own_department_groups',
  delete_workers = 'delete_workers',
  see_only_dispatchers = 'see_only_dispatchers',
  edit_job_with_approved_timesheets = 'edit_job_with_approved_timesheets',
  adjust_ces_verified_timesheets = 'adjust_ces_verified_timesheets',
  generate_tecorien_payroll_report = 'generate_tecorien_payroll_report',
  extend_job_end_time = 'extend_job_end_time',
  sign_timesheet = 'sign_timesheet',
  edit_job_department_section = 'edit_job_department_section',
  see_deleted_notes = 'see_deleted_notes',
  can_see_scheduling = 'can_see_scheduling',
  generate_workers_total_hours_report = 'generate_workers_total_hours_report',
  can_edit_timesheet_files = 'can_edit_timesheet_files',
  generate_ces_supervisors_total_hours_report = 'generate_ces_supervisors_total_hours_report',
  can_revert_worker_status = 'can_revert_worker_status',
  can_verify_jobs = 'can_verify_jobs',
  generate_department_invoices_report = 'generate_department_invoices_report',
  generate_paid_timesheets_report = 'generate_paid_timesheets_report',
  generate_billed_timesheets_report = 'generate_billed_timesheets_report',
  generate_unpaid_invoices_report = 'generate_unpaid_invoices_report',
  generate_suspicious_flagging_timesheets_report = 'generate_suspicious_flagging_timesheets_report',
  create_signage_jobs_without_request_time_limitation = 'create_signage_jobs_without_request_time_limitation',
  can_clone_job = 'can_clone_job',
  override_timesheets_min_flagging_hours_limit = 'override_timesheets_min_flagging_hours_limit',
  can_split_job = 'can_split_job',
  reroute_to_new_job = 'reroute_to_new_job',
  see_all_roles = 'see_all_roles',
  update_own_departments = 'update_own_departments',
  can_see_project_settings = 'can_see_project_settings',
  cancel_integrated_job_shifts = 'cancel_integrated_job_shifts',
  delete_any_job_photo = 'delete_any_job_photo',
  adjust_ce_verified_timesheets = 'adjust_ce_verified_timesheets',
  edit_any_timesheet_comment = 'edit_any_timesheet_comment',
  can_open_master_timesheet = 'can_open_master_timesheet',
  can_do_job_action = 'can_do_job_action',
  set_only_own_department_for_job = 'set_only_own_department_for_job',
  change_job_emergency_type = 'change_job_emergency_type',
}

export default AppPermissions;
