import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import moment, { Moment } from 'moment';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { Box, TextField, Button } from '@mui/material';

import { showErrorMessage } from 'Utils/errorMessage';
import AppPaperModal from 'components/AppPaperModal';

import { EditDateTimeValidationSchema } from './EditDateTimeValidation';

const timeFormatsMap = {
  date: 'YYYY-MM-DD',
  'datetime-local': 'YYYY-MM-DDTHH:mm',
};

type Config<T> = { start: T; finish: T };

type Props = {
  defaultValue: Config<string | Moment | Date>;
  labels: Config<string>;
  inputType?: 'date' | 'datetime-local';
  open?: boolean;
  onClose?: () => void;
  onSuccess?: (startDate: Moment, finishDate: Moment) => Promise<any>;
  title?: string;
  subTitle?: string;
  confirmButtonTitle?: string;
  allowEmptyDate?: boolean;
  jobRequestDate?: string;
};

type FormikValues = {
  start_at: string;
  finish_at: string;
  dates_required: boolean;
  requestDate: Moment;
};

const EditDateAndTime = ({
  title = 'Edit',
  subTitle = 'You can change start and end time',
  confirmButtonTitle = 'Edit',
  allowEmptyDate = false,
  defaultValue = { start: '', finish: '' },
  labels = { start: '', finish: '' },
  inputType = 'date',
  open = false,
  onClose = () => {},
  onSuccess = async () => null,
  jobRequestDate = null,
}: Props) => {
  const startValue = useMemo(() => {
    if (defaultValue.start) {
      return defaultValue.start;
    }
    if (jobRequestDate) {
      return jobRequestDate;
    }
    if (!allowEmptyDate) {
      return new Date();
    }
    return null;
  }, [defaultValue.start, jobRequestDate, allowEmptyDate]);

  const finishValue = useMemo(() => {
    if (defaultValue.finish) {
      return defaultValue.finish;
    }
    if (startValue) {
      return moment(startValue).add('1', inputType === 'date' ? 'days' : 'hours');
    }
    if (!allowEmptyDate) {
      return new Date();
    }
    return null;
  }, [defaultValue.finish, startValue, inputType, allowEmptyDate]);

  const onConfirm = async ({ start_at, finish_at }: FormikValues) => {
    try {
      await onSuccess(moment(start_at), moment(finish_at));
      onClose();
    } catch (e) {
      showErrorMessage(e);
    }
  };

  const initialValues = useMemo(
    () => ({
      start_at: startValue ? moment(startValue).format(timeFormatsMap[inputType]) : '',
      finish_at: finishValue ? moment(finishValue).format(timeFormatsMap[inputType]) : '',
      dates_required: !allowEmptyDate,
      ...(jobRequestDate && { requestDate: moment(jobRequestDate) }),
    }),
    [startValue, finishValue, inputType, allowEmptyDate, jobRequestDate]
  );

  const { values, errors, submitForm, isSubmitting, resetForm, setValues, handleChange } = useFormik<FormikValues>({
    initialValues,
    validationSchema: EditDateTimeValidationSchema,
    onSubmit: (values) => onConfirm(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!open) {
      setValues((prevValues) => ({
        ...prevValues,
        ...initialValues,
      }));
    }
  }, [open, initialValues, setValues]);

  return (
    <AppPaperModal
      open={open}
      onClose={onClose}
      submitButton={{
        title: confirmButtonTitle,
        onClick: submitForm,
        loading: isSubmitting,
      }}
      title={title}
      subtitle={subTitle}
    >
      <Box display="flex" flexDirection="column" gap={'20px'} width={350}>
        <TextField
          label={labels?.start || 'Start Datetime'}
          type={inputType}
          variant="outlined"
          name="start_at"
          onChange={handleChange}
          value={values.start_at}
          error={!!errors.start_at}
          helperText={errors.start_at}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label={labels?.finish || 'Finish Datetime'}
          type={inputType}
          variant="outlined"
          name="finish_at"
          onChange={handleChange}
          value={values.finish_at}
          error={!!errors.finish_at}
          helperText={errors.finish_at}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      {(errors.start_at || errors.finish_at) && (
        <Button
          startIcon={<RotateLeftIcon />}
          variant="text"
          onClick={() => resetForm()}
          style={{ textDecorationLine: 'underline', width: '100%' }}
        >
          Reset to initial values
        </Button>
      )}
    </AppPaperModal>
  );
};

export default EditDateAndTime;
