import { Component } from 'react';
import { connect } from 'react-redux';

import GoogleMapReact from 'google-map-react';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { FormControlLabel, FormGroup, Switch, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { JobLocation } from 'types/Job';
import { ImageWithAuthor } from 'types/Location';

import { EnvVariables } from 'Constants/app';
import { JobType } from 'Constants/job';
import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import { canDeleteImage } from 'Containers/Job/dialogs/LocationsPhotoEdit/helpers';
import { generateLocationImageName } from 'Containers/Job/utils';
import Point from 'Containers/Maps/Point';
import { CurrentLocation } from 'Models/geoLocation';
import { actions } from 'Services';
import AddressAutocomplete from 'components/AddressAutocomplete/AddressAutocomplete';
import FacilityAutocomplete from 'components/FacilityAutocomplete/FacilityAutocomplete';
import SwitchOnOff from 'components/SwitchOnOff/SwitchOnOff';

interface JobLocationState extends JobLocation {
  workersCount: number;
}

type Restriction = {
  allowed: boolean;
  reason?: string;
};
interface Props {
  location: JobLocationState;
  location_index: number;
  onChange: (updatedLocation: Partial<JobLocationState>, locationIndex: number) => void;
  onRemove: (locationIndex: number) => void;
  currentLocation: any;
  errors: any;
  handleBlur: () => void;
  touchedSubmit: boolean;
  canEditImportedLocation?: boolean;
  can_edit_location?: boolean;
  withMap?: boolean;
  jobType?: JobType;
  flagging?: boolean;
  busyLocation?: boolean;
  editStructure?: Restriction;
  editImage?: Restriction;
  editComment?: Restriction;
  jobId?: string | number;
  canCreateIntegrationJob?: boolean;
  searchByFacility?: boolean;
  onChangeSearchByFacility?: (value: boolean) => void;
}

const defaultMapCenter = {
  lat: Number(EnvVariables.MapCenterLat),
  lng: Number(EnvVariables.MapCenterLng),
};

type State = {
  structureByFacilityID: string;
};

class MapSelect extends Component<Props, State> {
  state = {
    structureByFacilityID: '',
  };

  static defaultProps: Partial<Props> = {
    withMap: true,
    editStructure: { allowed: true, reason: '' },
    editImage: { allowed: true, reason: '' },
    editComment: { allowed: true, reason: '' },
    canCreateIntegrationJob: false,
  };

  selectLocation: Parameters<typeof AddressAutocomplete>['0']['onChange'] = (
    { address = '', lat = 0, lon = 0 } = { name: '', address: '', lat: 0, lon: 0 }
  ) => {
    if (!address) {
      this.resetLocation();
      return;
    }
    const updated_location = {
      ...this.props.location,
      address,
      lat,
      lng: lon,
    };
    this.props.onChange(updated_location, this.props.location_index);
    this.forceUpdate();
  };

  handleChangeLocation: Parameters<typeof FacilityAutocomplete>['0']['onSelectLocation'] = ({
    address = '',
    lat = 0,
    lng = 0,
    structure = '',
    facility_id = '',
  }) => {
    if (!address) {
      this.resetLocation();
      return;
    }
    const updated_location = {
      ...this.props.location,
      address,
      lat,
      lng,
      structure: structure || '',
      facility_id,
    };
    this.props.onChange(updated_location, this.props.location_index);
    this.setState({ structureByFacilityID: structure });
    this.forceUpdate();
  };

  resetLocation = () => {
    this.setState({ structureByFacilityID: '' });
    this.props.onChange(
      {
        address: null,
        lat: 0,
        lng: 0,
        max_workers: 1,
        structure: '',
        workersCount: 0,
        id: this.props.location.id,
      },
      this.props.location_index
    );
  };

  removeLocation = () => {
    this.props.onRemove(this.props.location_index);
  };

  fieldChanged = (event) => {
    const location = {
      ...this.props.location,
      [event.target.name]: event.target.value,
    };
    this.props.onChange(location, this.props.location_index);
  };

  get mapCenter() {
    if (this.props.location) {
      if (this.props.location) {
        return {
          lat: this.props.location.lat,
          lng: this.props.location.lng,
        };
      }
    }
    const currentLocation = this.props.currentLocation;
    if (currentLocation) {
      return {
        lat: Number(currentLocation.Latitude),
        lng: Number(currentLocation.Longtitude),
      };
    } else {
      navigator.geolocation.getCurrentPosition(function (position) {
        const currentLocation: CurrentLocation = {
          Longtitude: position.coords.longitude,
          Latitude: position.coords.latitude,
        };
        actions.MapActions.setCurrentLocation(currentLocation);
        return {
          lat: Number(position.coords.latitude),
          lng: Number(position.coords.longitude),
        };
      });
    }
    return defaultMapCenter;
  }

  render() {
    const { location, location_index, errors, handleBlur, touchedSubmit, jobId, canCreateIntegrationJob } = this.props;
    const { can_edit_location, canEditImportedLocation, busyLocation } = this.props;
    const { editComment, editStructure, editImage } = this.props;
    const showLocationSearch = can_edit_location && canEditImportedLocation;
    const canRemoveLocation = location_index > 0 && !busyLocation;
    return (
      <>
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group relative">
              {canCreateIntegrationJob && (
                <FormGroup
                  sx={{
                    width: 'max-content',
                    marginBottom: '16px',
                  }}
                >
                  <Tooltip
                    title={location_index !== 0 ? 'The search type depends on the first location search type' : ''}
                  >
                    <FormControlLabel
                      onChange={() => {
                        this.resetLocation();
                        this.props.onChangeSearchByFacility?.(!this.props.searchByFacility);
                      }}
                      control={
                        <SwitchOnOff
                          disabled={location_index !== 0 || !can_edit_location || !canEditImportedLocation}
                          checked={this.props.searchByFacility}
                        />
                      }
                      label="Search by Facility ID"
                    />
                  </Tooltip>
                </FormGroup>
              )}

              <div className="d-flex" style={{ marginBottom: '28px' }}>
                <div className="d-block pr-4" style={{ flex: 1, position: 'relative' }}>
                  <label className="d-block">
                    Location #{location_index + 1}
                    {!can_edit_location && ' - Please Re-Route instead of editing location'}
                    {this.props.searchByFacility && Boolean(location.facility_id) && (
                      <span>
                        - Facility ID: <b>{location.facility_id}</b>
                      </span>
                    )}
                  </label>
                  {showLocationSearch ? (
                    !this.props.searchByFacility ? (
                      <AddressAutocomplete
                        onChange={this.selectLocation}
                        disabled={!can_edit_location || !canEditImportedLocation}
                        value={{
                          address: location.address,
                          lat: location.lat,
                          lon: location.lng,
                        }}
                        name={'address'}
                        onBlur={handleBlur}
                        placeholder={'Enter address'}
                        size="small"
                      />
                    ) : (
                      <FacilityAutocomplete address={location.address} onSelectLocation={this.handleChangeLocation} />
                    )
                  ) : (
                    <b style={{ margin: 0 }}>{location.address}</b>
                  )}
                  {touchedSubmit && errors && (
                    <p className="error error-location">
                      {Array.isArray(errors)
                        ? errors.map((error, index) => {
                            return index === location_index ? (error ? error.address : '') : null;
                          })
                        : errors}
                    </p>
                  )}
                </div>
                <div className="address-list-select" style={{ flex: 1 }}>
                  <label className="d-block">Structure #</label>
                  <Tooltip disableInteractive title={!editStructure.allowed ? editStructure.reason : ''} arrow>
                    <input
                      style={{ border: '1px solid lightgrey' }}
                      className="ce-form-control"
                      placeholder="Structure #"
                      data-type={'text'}
                      value={location.structure ? location.structure : ''}
                      name={'structure'}
                      onChange={this.fieldChanged}
                      disabled={!editStructure.allowed || Boolean(this.state.structureByFacilityID)}
                    />
                  </Tooltip>

                  {touchedSubmit && errors && (
                    <p style={{ color: '#a94442', margin: 0 }}>
                      {Array.isArray(errors) ? errors[location_index]?.structure || '' : errors}
                    </p>
                  )}
                </div>

                {canRemoveLocation && (
                  <div style={{ position: 'absolute', right: -5, top: -20 }}>
                    <Tooltip
                      disableInteractive
                      title={!canEditImportedLocation ? 'Cannot remove imported job locations' : ''}
                      arrow
                    >
                      <div>
                        <IconButton
                          aria-label="delete"
                          onClick={this.removeLocation}
                          disabled={!canEditImportedLocation}
                        >
                          <DeleteForeverOutlinedIcon style={{ color: '#BDBDBD' }} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              {this.props.withMap && location && location.lat !== 0 && location.lng !== 0 ? (
                <div style={{ height: '200px', width: 'auto', marginBottom: 20 }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: EnvVariables.GoogleMapApiKey,
                    }}
                    center={this.mapCenter}
                    defaultCenter={defaultMapCenter}
                    yesIWantToUseGoogleMapApiInternals
                    defaultZoom={11}
                  >
                    <Point lat={location.lat} lng={location.lng}>
                      {location_index}
                    </Point>
                  </GoogleMapReact>
                </div>
              ) : null}

              <ImageUpload
                onChangeImage={
                  editImage.allowed
                    ? (images) =>
                        this.fieldChanged({
                          target: {
                            name: 'images',
                            value: images,
                          },
                        })
                    : undefined
                }
                disabled={!editImage.allowed}
                defaultImages={location ? location.images : []}
                jobType={this.props.jobType}
                canRemove={(image: ImageWithAuthor) => canDeleteImage(image)}
                getDownloadedImageName={({ updated_at }) =>
                  generateLocationImageName({
                    jobId,
                    address: location.address,
                    updated_at,
                    structure: location.structure,
                  })
                }
              />

              <div className="form-group">
                <label className="d-block">{this.props.flagging ? 'Flagging points' : 'Comments (optional)'}</label>
                <textarea
                  rows={5}
                  placeholder={this.props.flagging ? 'Add Flagging points' : 'Comments (optional)'}
                  className="ce-form-control-textarea"
                  value={location && location.note ? location.note : ''}
                  name={'note'}
                  onChange={this.fieldChanged}
                  style={{ height: 70 }}
                  disabled={!editComment.allowed}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLocation: state.map.currentLocation,
  };
}

export default connect(mapStateToProps, null)(MapSelect);
