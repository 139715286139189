import { CommentsAPI } from 'Services/API';

import * as actionTypes from './actionTypes';

export function retrieve(entity_type, entity_id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.GET_COMMENTS_REQUEST });

      const response = await CommentsAPI.getAll({
        entity_type: entity_type,
        entity_id: entity_id,
      });
      dispatch({ type: actionTypes.GET_COMMENTS_SUCCESS, comments: response.comments });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.GET_COMMENTS_ERROR });
      throw error;
    }
  };
}

export function create(data): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.CREATE_COMMENTS_REQUEST });
      const response = await CommentsAPI.create(data);
      dispatch({ type: actionTypes.CREATE_COMMENTS_SUCCESS, comment: response });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.CREATE_COMMENTS_ERROR });
      throw error;
    }
  };
}

export function update(id, newComment: string): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.UPDATE_COMMENTS_REQUEST });
      const response = await CommentsAPI.update(id, { comment: newComment });
      dispatch({ type: actionTypes.UPDATE_COMMENTS_SUCCESS, comment: response.comment });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_COMMENTS_ERROR });
      throw error;
    }
  };
}

export function deleteComment(id): any {
  return async function (dispatch, getState) {
    try {
      dispatch({ type: actionTypes.DELETE_COMMENTS_REQUEST });
      const response = await CommentsAPI.delete(id);
      dispatch({ type: actionTypes.DELETE_COMMENTS_SUCCESS, comment: { response, id } });
      return response;
    } catch (error) {
      dispatch({ type: actionTypes.DELETE_COMMENTS_ERROR });
      throw error;
    }
  };
}
