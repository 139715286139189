import React, { Component } from 'react';
import 'react-redux';
import { connect, ConnectedProps } from 'react-redux';

import moment from 'moment';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Box, Button, FormControlLabel, TextField, Tooltip, Typography } from '@mui/material';

import { Department } from 'types/Common/Companies';
import { CC_User, ConEdRequestor, ConEdSupervisor, PreferredWorker } from 'types/Common/User';
import { Job, JobLocation } from 'types/Job';
import { ImageWithAuthor } from 'types/Location';

import { shouldShowDepartmentOnJobCreation } from 'Constants/companies';
import { JOB_STATUSES, JobStatusHelper, JobType, JobTypeHelper, POET_NUMBER } from 'Constants/job';
import { EROLES } from 'Constants/user';
import { FORMATS } from 'Utils/Date';
import UserPermissions from 'Utils/PermissionsHelper';
import app_history from 'app_history';
import AppSwitch from 'components/AppSwitch/AppSwitch';
import { ReduxState } from 'createStore';

import DepartmentMaterialAsyncSearch from '../Components/Controls/DepartmentMaterialAsyncSearch';
import MapSelect from '../Components/Controls/MapSelect';
import MunicipalitiesMaterialAsyncSearch from '../Components/Controls/MunicipalitiesMaterialAsyncSearch';
import RequestorMaterialAsyncSearch from '../Components/Controls/RequestorMaterialAsyncSearch';
import SupervisorsMaterialAsyncSearch from '../Components/Controls/SupervisorMaterialAsyncSearch';
import UsersMaterialAsyncSearch from '../Components/Controls/UsersMaterialAsyncSearch';
import WorkersMaterialAsyncSearch from '../Components/Controls/WorkersMaterialAsyncSearch';
import ImageUpload from '../Components/ImageUpload/ImageUpload';
import './JobForm.scss';
import ContactToMenu from './components/ContactToMenu';
import JobInputBlock from './components/JobInputBlock';
import DeleleJob from './dialogs/DeleteJobDialog';
import { canDeleteImage } from './dialogs/LocationsPhotoEdit/helpers';
import { TextButton } from './styled';
import { getCanEditEndDate } from './utils';

interface JobLocationState extends JobLocation {
  workersCount: number;
}
export interface JobCreateEdit
  extends Omit<Job, 'ccUser' | 'department' | 'requestor' | 'supervisor' | 'preferredWorkers'> {
  department: Department;
  requestor: ConEdRequestor;
  supervisor?: ConEdSupervisor;
  locations: JobLocationState[];
  ccUser: { label: string; value: CC_User }[];
  preferredWorkerIds: number[];
  preferredWorkers?: PreferredWorker[];
  is_standby: 0 | 1;
}

interface Props extends PropsFromRedux {
  onJobFormChange: (index: number, name: string, value: JobCreateEdit[keyof JobCreateEdit]) => void;
  index: number;
  job: JobCreateEdit;
  remove?: boolean;
  touchedSubmit?: boolean;
  errors: { jobs: any[] };
  handleBlur: (event?: React.FocusEvent<HTMLInputElement>) => void;
  validateForm: (values?: any) => Promise<any>;
  removeJob?: (index: number) => void;
  can_edit_location: boolean;
  isEdit: boolean;
  isCopy?: boolean;
}

interface State {
  initJob: JobCreateEdit;
  open_delete_job: boolean;
  addedLocation: boolean;
  prevMaxWorkers: number;
  searchByFacility: boolean;
}

class JobFormComponent extends Component<Props, State> {
  times: any = {};
  state = {
    initJob: { ...this.props.job },
    open_delete_job: false,
    addedLocation: false,
    prevMaxWorkers: 0,
    searchByFacility: Boolean(this.props.job?.locations[0]?.facility_id),
  };
  symbolsArr: any = ['e', 'E', '+', '-', '.'];
  fields: any = {
    requestTime: this.props.job.requestTime ? moment(this.props.job.requestTime).format('YYYY-MM-DDTHH:mm:ss') : null,
    jobType: this.props.job.jobType ? this.props.job.jobType : null,
  };

  sectionToDepartmentMap: { [key: number]: Department } = {};

  static defaultProps = {
    isCopy: false,
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.departments !== this.props.departments) {
      this.sectionToDepartmentMap = {};
      this.props.departments.asMutable({ deep: true }).forEach((department) => {
        if (department.section) {
          this.sectionToDepartmentMap[department.section] = department;
        }
      });
    }
    if (prevProps.job.department !== this.props.job.department) {
      this.handleChangeFieldValue('account_no', '');
      setTimeout(() => {
        const municipalityPoet = this.props.job?.municipality ? POET_NUMBER[this.props.job.municipality.label] : '';
        const updatedPoet = this.props.job?.department?.is_service_dept ? '' : municipalityPoet;
        this.handleChangeFieldValue('poet', updatedPoet);
      });
    }
  }

  openDeleteJob = (event) => {
    this.setState({
      open_delete_job: true,
    });
  };

  closeDeleteJob = () => {
    this.setState({
      open_delete_job: false,
    });
  };

  getErrorMessage = (key) => {
    if (!this.props.errors) {
      return null;
    }
    const jobs = this.props.errors.jobs;
    if (!jobs || !Array.isArray(jobs) || !jobs[this.props.index]) {
      return null;
    }
    return jobs[this.props.index][key];
  };

  handleChangeField = (event) => {
    const {
      currentTarget: { name, value, type, dataset },
    } = event;
    if (type === 'number' || dataset.type === 'number') {
      if (name === 'maxWorkers' && Number(value) !== 0 && value >= this.props.job.locations.length) {
        this.setState({ prevMaxWorkers: Number(value) });
      }
      if (name === 'maxWorkers' && Number(value) < this.props.job.locations.length) {
        this.setState({ prevMaxWorkers: this.props.job.maxWorkers });
      }
      return this.handleChangeFieldValue(name, Number(value));
    }
    return this.handleChangeFieldValue(name, value);
  };

  onLocationChange = (location: any, index) => {
    const locations = this.props.job.locations.map((locItem, ind) => {
      return ind === index ? { ...location, id: locItem.id } : locItem;
    });

    this.handleChangeFieldValue('locations', locations);
  };

  onLocationRemove = (locationIndex) => {
    const newLocations = this.props.job.locations.filter((location, index) => locationIndex !== index);

    this.handleChangeFieldValue('locations', newLocations);
  };

  handleChangeFieldValue = (name, value) => {
    if (name === 'department' && value) {
      this.fields = {
        ...this.fields,
        department: value.id,
      };
    } else if (name === 'requestTime' && value) {
      this.fields = {
        ...this.fields,
        [name]: moment(value).format('YYYY-MM-DDThh:mm:ss'),
      };
    } else {
      this.fields = {
        ...this.fields,
        [name]: value,
      };
    }
    setTimeout(() => {
      this.props.validateForm();
    }, 100);
    return this.props.onJobFormChange(this.props.index, name, value);
  };

  handleChange = ({ currentTarget: { name, value } }) => {
    const isTimeChanged = ['requestTime', 'endTime'].includes(name);
    const newValue = value && isTimeChanged ? moment(value).format(FORMATS.jobCreateDate) : value;
    this.props.onJobFormChange(this.props.index, name, newValue);

    this.handleChangeFieldValue(name, newValue);
  };

  changeDepartment = (departments: Department[]) => {
    const department = departments[0] ? departments[0] : null;
    if (department?.section && !this.props.job.section) {
      setTimeout(() => this.handleChangeFieldValue('section', department.section));
    }
    this.handleChangeFieldValue('department', department);
  };

  handleMunicipality(name, value) {
    this.handleChangeFieldValue(name, value);
    setTimeout(() => {
      if (this.props.job.department?.is_service_dept) return;
      const updatedPoet = value && POET_NUMBER[value?.label] ? POET_NUMBER[value?.label] : '';
      this.handleChangeFieldValue('poet', updatedPoet);
    });
  }

  changeJobPdfs = (pdfs) => {
    this.handleChangeFieldValue('jobPdfs', pdfs);
  };

  goBack = () => {
    app_history.goBack();
  };

  addNewLocation = () => {
    if (this.props.job.locations.length > 0 && this.props.job.locations[0]) {
      const newLocation = {
        ...this.props.job.locations[0],
        structure: '',
        images: [],
        note: '',
      };
      delete newLocation.id;
      this.props.onJobFormChange(this.props.index, 'locations', [...this.props.job.locations, newLocation]);
      this.setState({ addedLocation: true });
    }
  };

  changeSearchByFacility = (searchByFacility: boolean) => {
    this.setState({ searchByFacility });
  };

  render() {
    const { job, handleBlur, touchedSubmit, isEdit, isCopy } = this.props;
    const inputProps = { min: moment(new Date(2022, 0)).format(FORMATS.jobCreateDate) };
    if (job == null) {
      return null;
    }
    const { workers, reroute_jobs } = job;
    const { isNew, isInProgress } = new JobStatusHelper(job.jobStatus);

    const jobHasApprovedTimesheets = job.exist_approved_timesheets;
    const cannotEditJobWithApprovedTimesheets =
      jobHasApprovedTimesheets && !UserPermissions.has.adjust_ce_verified_timesheets;
    const haveEditImportedPermission = false;
    const can_add_new_location = job?.locations[0]?.address && !cannotEditJobWithApprovedTimesheets;
    const canEditImportedLocation: boolean = job.coned_imported ? haveEditImportedPermission : true;
    const canAddLocation: boolean = job.coned_imported
      ? haveEditImportedPermission && can_add_new_location
      : can_add_new_location;
    const notNewJob = job.status !== 'new';
    const { isSignage, isFlagging, isParking } = new JobTypeHelper(job.jobType);
    const canEditAllJobs = UserPermissions.has.edit_all_jobs;
    const canEditJobInfo = UserPermissions.has.edit_job_info;
    const cannotSelectJobStatus =
      (isEdit && ((canEditJobInfo && !canEditAllJobs) || notNewJob || isSignage)) || Boolean(job.coned_imported);

    const alreadyBusyLocations: number[] = workers.map(({ locationID = 0 }) => locationID);
    const canEditImportedJob = UserPermissions.has.edit_imported_job;
    const sectionDepartment = this.sectionToDepartmentMap[Number(this.props.job?.section)];
    const sectionNotMatchDepartment = !!sectionDepartment && sectionDepartment.id !== job.department?.id;

    const finishDateDisabled =
      (job.jobStatus && !isNew) ||
      (isEdit && canEditJobInfo && !canEditAllJobs && !(job.coned_imported && canEditImportedJob)) ||
      cannotEditJobWithApprovedTimesheets;

    const canSeeFinishDate = getCanEditEndDate({
      jobType: job.jobType,
      department: job.department,
      isEdit,
      isStandby: Boolean(job.is_standby),
    });

    const selectedDepartment = this.props.departments.find((dep) => dep.id === job.department?.id);

    return (
      <>
        <div>
          <div className="row justify-content-between ml-1 mr-3">
            <Box marginBottom={2}>
              <span className="text-title-job">Job # {this.props.index + 1}</span>
            </Box>

            <div className="row">
              <div className="arrow_backward mt-1 mr-1" />
              <span
                style={{ textDecoration: 'none' }}
                className="text-14 text-blue cursor-pointer"
                onClick={this.goBack}
              >
                Back to list
              </span>
            </div>
          </div>
          <div className="block-white">
            <div className="row job-type-row">
              <div className="job-type-grid-field">
                <Tooltip
                  title={job.coned_imported && 'Job type cannot be changed for imported jobs.'}
                  disableInteractive
                >
                  <div className="group-grid group-job-type w-100 " role="group" style={{ position: 'relative' }}>
                    <button
                      disabled={cannotSelectJobStatus}
                      type="button"
                      className={`btn ${isFlagging ? 'active' : 'btn-outline-secondary'}`}
                      value={JobType.Flagging}
                      data-type="number"
                      name={'jobType'}
                      onClick={this.handleChangeField}
                    >
                      Flagging
                    </button>
                    <button
                      disabled={cannotSelectJobStatus}
                      type="button"
                      data-type="number"
                      name={'jobType'}
                      className={`btn ${isParking ? 'active' : 'btn-outline-secondary'}`}
                      value={JobType.Parking}
                      onClick={this.handleChangeField}
                    >
                      Parking
                    </button>
                    <button
                      disabled={isEdit}
                      type="button"
                      data-type="number"
                      name={'jobType'}
                      className={`btn ${isSignage ? 'active' : 'btn-outline-secondary'}`}
                      value={JobType.Signage}
                      onClick={this.handleChangeField}
                    >
                      Signage
                    </button>
                    <p className="error" style={{ top: 45, left: 0 }}>
                      {this.getErrorMessage('jobType')}
                    </p>
                  </div>
                </Tooltip>
              </div>
              <div className="request-date-time-wrap">
                <div className="request-date-time-field" style={{ position: 'relative' }}>
                  <TextField
                    id={'start-datetime-input'}
                    label=""
                    type="datetime-local"
                    variant="standard"
                    InputProps={{ disableUnderline: true, inputProps: { step: '60' } }}
                    name={'requestTime'}
                    onChange={this.handleChange}
                    disabled={
                      job.jobStatus === JOB_STATUSES.Cancelled ||
                      job.jobStatus === JOB_STATUSES.CancelledBillable ||
                      (isEdit && canEditJobInfo && !canEditAllJobs && !(job.coned_imported && canEditImportedJob)) ||
                      (job.jobStatus === JOB_STATUSES.Completed &&
                        !UserPermissions.has.update_completed_job_start_time) ||
                      cannotEditJobWithApprovedTimesheets
                    }
                    value={job.requestTime ?? ''}
                    // eslint-disable-next-line
                    inputProps={inputProps}
                  />
                  <p className="date-title">Start Date</p>
                </div>
                <p className="job-form-time-error" style={{ left: 0, top: 45 }}>
                  {this.getErrorMessage('requestTime')}
                </p>
                {(canSeeFinishDate || (isEdit && !!job.finish_at)) && (
                  <>
                    <Tooltip
                      title={
                        job.finish_at && isInProgress
                          ? 'To change finish date, use the Extend Job End Time feature'
                          : ''
                      }
                      disableInteractive
                    >
                      <div className="request-date-time-field" style={{ position: 'relative', marginTop: 15 }}>
                        <TextField
                          id={'finish-datetime-input'}
                          label=""
                          type="datetime-local"
                          variant="standard"
                          InputProps={{ disableUnderline: true, inputProps: { step: '60' } }}
                          name={'endTime'}
                          onChange={this.handleChange}
                          disabled={finishDateDisabled}
                          value={job.endTime ?? ''}
                          inputProps={inputProps}
                        />
                        <p className="date-title">Finish Date</p>
                      </div>
                    </Tooltip>
                    <p className="job-form-time-error" style={{ left: 0, top: 45 }}>
                      {this.getErrorMessage('endTime')}
                    </p>
                  </>
                )}
              </div>
            </div>
            {isFlagging && (
              <FormControlLabel
                name="is_standby"
                sx={{ m: 0, p: '12px 0 0 26px', width: 'max-content', gap: '10px' }}
                control={
                  <AppSwitch
                    checked={Boolean(job.is_standby)}
                    onChange={(e) => this.handleChangeFieldValue('is_standby', e.target.checked ? 1 : 0)}
                  />
                }
                label="Standby"
                disabled={isEdit && reroute_jobs.length > 0}
              />
            )}
          </div>
          <div className="block-white">
            <div className="row">
              <div className="form-group col-sm-6">
                <span className="job-label-with-button">
                  <label style={{ margin: 0 }}>Department</label>
                  <Tooltip
                    disableInteractive
                    title={
                      sectionDepartment
                        ? sectionDepartment.id === job.department?.id
                          ? 'Section match with the department'
                          : `Will be applied: ${sectionDepartment.name}`
                        : 'You can write section number and set relevant department by clicking on this button'
                    }
                  >
                    <span>
                      <TextButton
                        disabled={!sectionDepartment}
                        color={sectionDepartment?.id === job.department?.id ? 'inherit' : 'primary'}
                        onClick={() => this.changeDepartment([sectionDepartment])}
                      >
                        Get from Section
                      </TextButton>
                    </span>
                  </Tooltip>
                </span>
                <DepartmentMaterialAsyncSearch
                  disabled={job.coned_imported || cannotEditJobWithApprovedTimesheets}
                  notMulty
                  onlySingleDepartment
                  noLabel
                  departments={[this.props.job.department]}
                  onSelect={this.changeDepartment}
                  onlyOwnDept={UserPermissions.has.set_only_own_department_for_job}
                  searchFilterFunction={shouldShowDepartmentOnJobCreation}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('department')}</p>}
              </div>
              <div className="form-group col-sm-6">
                <span className="job-label-with-button">
                  <label style={{ margin: 0 }}>Section</label>
                  {sectionNotMatchDepartment && (
                    <TextButton onClick={() => this.handleChangeFieldValue('section', job.department?.section)}>
                      Reset
                    </TextButton>
                  )}
                </span>

                <Tooltip
                  title={
                    sectionNotMatchDepartment
                      ? `${job.department?.name} has pre-setted section: ${job.department?.section}. If you do a mistake you can correct Section # by clicing Reset button or apply new department by clicking on Get from Section button`
                      : ''
                  }
                >
                  <input
                    className="ce-form-control"
                    style={{
                      paddingRight: '2rem',
                      borderColor: sectionNotMatchDepartment ? '#c9a310' : undefined,
                      borderWidth: sectionNotMatchDepartment ? 3 : undefined,
                    }}
                    placeholder="Enter Section Name"
                    name={'section'}
                    value={job.section || ''}
                    onBlur={handleBlur}
                    onChange={this.handleChangeField}
                    disabled={job.coned_imported || cannotEditJobWithApprovedTimesheets}
                  />
                </Tooltip>
                {touchedSubmit && <p className="error">{this.getErrorMessage('section')}</p>}
                <div className="write-pen" />
              </div>
              <div className="form-group col-sm-6 relative">
                <label className="d-block">Requestor</label>
                {job.requestor?.id ? (
                  <ContactToMenu
                    name="Requestor"
                    userName={job.requestor.name}
                    id={job.requestor.id}
                    phoneNumber={job.requestor.phoneNumber}
                    email={job.requestor.email}
                  />
                ) : null}
                <RequestorMaterialAsyncSearch
                  noLabel
                  disabled={job.coned_imported || cannotEditJobWithApprovedTimesheets}
                  departmentId={job.department ? job.department.id : 0}
                  value={
                    job.requestor
                      ? {
                          label: job.requestor.name,
                          value: job.requestor,
                        }
                      : undefined
                  }
                  onSelect={(item) => {
                    this.handleChangeFieldValue('requestor', item ? item.value : null);
                  }}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('requestor')}</p>}
              </div>
              <div className="form-group col-sm-6 relative">
                <label className="d-block">Supervisor</label>
                {job.supervisor?.id ? (
                  <ContactToMenu
                    name="Supervisor"
                    userName={job.supervisor.name}
                    id={job.supervisor.id}
                    phoneNumber={job.supervisor.phoneNumber}
                    email={job.supervisor.email}
                  />
                ) : null}
                <SupervisorsMaterialAsyncSearch
                  noLabel
                  disabled={job.coned_imported || cannotEditJobWithApprovedTimesheets}
                  departmentId={job.department ? job.department.id : ''}
                  defaultSupervisor={job.supervisor}
                  selectedId={job.supervisor?.id}
                  onSelect={(item) => {
                    this.handleChangeFieldValue('supervisor', item || null);
                  }}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('supervisor')}</p>}
              </div>
              <div className="form-group col-sm-6">
                <label className="d-block">CC User</label>
                <UsersMaterialAsyncSearch
                  isMulty
                  noLabel
                  limitTags={2}
                  disabled={cannotEditJobWithApprovedTimesheets}
                  usersNotAvailable={[
                    job.supervisor ? job.supervisor.id : null,
                    job.requestor ? job.requestor.id : null,
                  ]}
                  searchParams={{
                    'roles[]': [
                      EROLES.requestor,
                      EROLES.department_supervisor,
                      EROLES.coned_field_supervisor,
                      EROLES.coned_billing_admin,
                    ],
                    include_cc_users: true,
                  }}
                  value={
                    job.ccUser
                      ? job.ccUser.map(
                          (user) =>
                            user &&
                            user.value && {
                              label: `${user.value.firstName} ${user.value.lastName}`,
                              value: user.value,
                            }
                        )
                      : undefined
                  }
                  onSelect={(item) => {
                    this.handleChangeFieldValue('ccUser', item ? item : null);
                  }}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('ccUser')}</p>}
              </div>
              <div className="form-group col-sm-6">
                <label className="d-block">Municipality</label>
                <MunicipalitiesMaterialAsyncSearch
                  noLabel
                  disabled={
                    job.coned_imported ||
                    cannotEditJobWithApprovedTimesheets ||
                    (!!job.jobStatus && job.jobStatus !== JOB_STATUSES.New)
                  }
                  value={
                    job.municipality
                      ? {
                          label: job.municipality.label,
                          value: job.municipality,
                        }
                      : undefined
                  }
                  onSelect={(item) => this.handleMunicipality('municipality', item ? item : null)}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('municipality')}</p>}
              </div>

              <div className="form-group col-sm-6">
                <label className="d-block">Max workers</label>
                <input
                  disabled={
                    isSignage ||
                    (isEdit &&
                      ((canEditJobInfo && !canEditAllJobs && !(job.coned_imported && canEditImportedJob)) ||
                        cannotEditJobWithApprovedTimesheets))
                  }
                  type="number"
                  className="ce-form-control"
                  placeholder="Enter max workers"
                  name={'maxWorkers'}
                  min="0"
                  step="1"
                  max={50}
                  value={job.maxWorkers || ''}
                  onBlur={handleBlur}
                  onChange={this.handleChangeField}
                  onKeyDown={(e) => this.symbolsArr.includes(e.key) && e.preventDefault()}
                />
                {touchedSubmit && <p className="error">{this.getErrorMessage('maxWorkers')}</p>}
              </div>
              <div className="form-group col-sm-6">
                <label className="d-block">Preferred Workers</label>
                <WorkersMaterialAsyncSearch
                  limitTags={2}
                  disabled={cannotEditJobWithApprovedTimesheets}
                  selectedWorkerIds={job.preferredWorkerIds}
                  placeholder="Select Preferred Workers"
                  onSelect={(workers) =>
                    this.handleChangeFieldValue(
                      'preferredWorkerIds',
                      workers.map(({ id }) => id)
                    )
                  }
                  filterConfig={(worker) =>
                    worker?.status?.toLowerCase() === 'active' &&
                    worker?.workerTypes.includes(isSignage ? JobType.Signage : job.jobType)
                  }
                />
              </div>
            </div>
          </div>
          <div className="block-white">
            <div className="row">
              <JobInputBlock label="PO #" error={this.getErrorMessage('po')}>
                <input
                  disabled={job.coned_imported && !canEditImportedJob}
                  className="ce-form-control"
                  placeholder="Enter PO Number"
                  data-type={'text'}
                  value={window.location.href.includes('copy') || !job.po ? undefined : job.po}
                  name={'po'}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
              <JobInputBlock label="Feeder #" error={this.getErrorMessage('feeder')}>
                <input
                  className="ce-form-control"
                  placeholder="Enter Feeder Number"
                  data-type={'string'}
                  name={'feeder'}
                  value={job.feeder || ''}
                  disabled={cannotEditJobWithApprovedTimesheets}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
              <JobInputBlock label="POET #" error={this.getErrorMessage('poet')}>
                <input
                  className="ce-form-control"
                  placeholder="Enter POET Number"
                  disabled={!selectedDepartment?.is_service_dept}
                  value={job.poet || ''}
                  name={'poet'}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
            </div>

            <div className="row">
              <JobInputBlock label="Work Request #" error={this.getErrorMessage('wr')}>
                <input
                  className="ce-form-control"
                  placeholder="Enter WR Number"
                  disabled={job.coned_imported || cannotEditJobWithApprovedTimesheets}
                  value={job.wr || ''}
                  name={'wr'}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
              <JobInputBlock label="Requisition #" error={this.getErrorMessage('requisition')}>
                <input
                  disabled={job.coned_imported && !canEditImportedJob}
                  className="ce-form-control"
                  placeholder="Enter Requisition #"
                  name={'requisition'}
                  data-type={'text'}
                  value={window.location.href.includes('copy') || !job.requisition ? undefined : job.requisition}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
              <JobInputBlock label="Receipt #" error={this.getErrorMessage('receipt')}>
                <input
                  className="ce-form-control"
                  placeholder="Enter Receipt Number"
                  name={'receipt'}
                  value={job.receipt || ''}
                  onChange={this.handleChangeField}
                />
              </JobInputBlock>
              {Boolean(selectedDepartment?.is_service_dept) && (
                <JobInputBlock label="Job #" error={this.getErrorMessage('account_no')}>
                  <input
                    className="ce-form-control"
                    placeholder="Enter Job Number"
                    value={job.account_no || ''}
                    name={'account_no'}
                    onChange={this.handleChangeField}
                  />
                </JobInputBlock>
              )}
            </div>
          </div>
          <div>
            {job.locations.map((location, index) => (
              <div key={`${index + this.props.index}`} className="block-white">
                <MapSelect
                  searchByFacility={this.state.searchByFacility}
                  onChangeSearchByFacility={this.changeSearchByFacility}
                  canEditImportedLocation={canEditImportedLocation}
                  editStructure={{
                    allowed: canEditImportedLocation && !cannotEditJobWithApprovedTimesheets,
                    reason: !canEditImportedLocation ? 'Cannot edit structure for imported job' : '',
                  }}
                  location={location}
                  busyLocation={!isCopy && alreadyBusyLocations.includes(location?.id)}
                  location_index={index}
                  flagging={isFlagging}
                  touchedSubmit={touchedSubmit}
                  handleBlur={handleBlur}
                  jobType={job.jobType}
                  can_edit_location={
                    isEdit
                      ? this.props.can_edit_location &&
                        location.workersCount === 0 &&
                        !cannotEditJobWithApprovedTimesheets
                      : this.props.can_edit_location
                  }
                  errors={this.getErrorMessage('locations')}
                  onChange={this.onLocationChange}
                  onRemove={this.onLocationRemove}
                  editImage={{ allowed: !cannotEditJobWithApprovedTimesheets }}
                  editComment={{ allowed: !cannotEditJobWithApprovedTimesheets }}
                  jobId={job.id}
                  canCreateIntegrationJob={!!job?.department?.create_integration_jobs}
                />
              </div>
            ))}

            <Box width="100%" display="flex" justifyContent="flex-end">
              <Button
                startIcon={<AddCircleIcon />}
                onClick={canAddLocation ? this.addNewLocation : () => {}}
                style={{
                  cursor: canAddLocation ? 'pointer' : 'not-allowed',
                }}
              >
                <Tooltip
                  disableInteractive
                  title={
                    canAddLocation
                      ? 'Add New Location'
                      : job.coned_imported && can_add_new_location
                      ? 'Cannot add new location for imported job'
                      : cannotEditJobWithApprovedTimesheets
                      ? 'You cannot add new location as job has approved timesheets'
                      : 'Add first location'
                  }
                  placement={'top'}
                >
                  <Typography
                    variant="body2"
                    color={canAddLocation ? 'primary' : 'rgb(128 130 131)'}
                    sx={{ textDecoration: 'underline', ml: 'auto' }}
                  >
                    Add Location
                  </Typography>
                </Tooltip>
              </Button>
            </Box>
          </div>
          <div className="block-white">
            <div className="form-group">
              <label className="d-block">Attach PDFs of emails to a Job (optional)</label>
              <ImageUpload
                filesMode
                defaultImages={job?.jobPdfs || []}
                onChangeImage={this.changeJobPdfs}
                canRemove={(image: ImageWithAuthor) => canDeleteImage(image)}
              />
            </div>
          </div>

          <div className="block-white">
            <div className="form-group">
              <label className="d-block">Job Comments (optional)</label>
              <textarea
                rows={5}
                placeholder="Add comments"
                className="ce-form-control-textarea"
                value={job.comment ? job.comment : ''}
                name={'comment'}
                onChange={this.handleChangeField}
              />
            </div>
            {this.props.remove && this.props.index > 0 && (
              <div className=" ml-1 row cursor-pointer" onClick={this.openDeleteJob}>
                <div className="delete-another-job" />
                <div className="text-16 delete-another-job-text">Delete Job</div>
              </div>
            )}
          </div>
        </div>

        <DeleleJob
          open={this.state.open_delete_job}
          onClose={this.closeDeleteJob}
          clicked={() => {
            this.props.removeJob(this.props.index);
            this.closeDeleteJob();
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state: ReduxState) {
  return {
    user: state.app.user,
    departments: state.app.departments,
  };
}

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(JobFormComponent);
