import { FC, useState } from 'react';

import { isEqual } from 'lodash';
import { ImmutableArray } from 'seamless-immutable';

import { ImageWithAuthor } from 'types/Location';

import ImageUpload from 'Containers/Components/ImageUpload/ImageUpload';
import { actions } from 'Services';
import { JobsAPI } from 'Services/API';
import FilesUpload from 'Utils/FilesUpload';
import { showErrorMessage } from 'Utils/errorMessage';
import useProcessing from 'Utils/hooks/useProcessing';
import { showSuccessMessage } from 'Utils/successMessage';
import AppPaperModal from 'components/AppPaperModal';
import { useAppDispatch } from 'createStore';

import { canDeleteImage } from './LocationsPhotoEdit/helpers';

type Props = {
  open: boolean;
  onClose: () => void;
  jobPDFs: ImmutableArray<ImageWithAuthor>;
  jobID: number;
};

const EditPDFAttachmentsModal: FC<Props> = ({ jobID, open, jobPDFs: initialFiles, onClose }) => {
  const dispatch = useAppDispatch();
  const [jobPDFs, setJobPDFs] = useState(initialFiles?.asMutable({ deep: true }) || []);
  const { promiseWrapper, inProcess } = useProcessing();

  const submit = async () => {
    try {
      const files = new FilesUpload(jobPDFs, 'pdfs_');
      if (!files.isAllFilesUploaded) {
        await files.uploadNew(JobsAPI.uploadImages);
      }
      await dispatch(actions.JobsActions.updateJobPDFs(jobID, { jobPdfs: files.allUploaded }));
      showSuccessMessage('PDF files updated successfully');
    } catch (error) {
      showErrorMessage(error);
    } finally {
      onClose();
    }
  };

  return (
    <AppPaperModal
      open={open}
      containerStyle={{ width: '100%', maxWidth: 500 }}
      onClose={onClose}
      title={'Edit PDF Files Attachments'}
      submitButton={{
        title: 'Save',
        onClick: () => promiseWrapper(submit()),
        disabled: isEqual(jobPDFs, initialFiles),
        loading: inProcess,
      }}
    >
      <ImageUpload filesMode defaultImages={jobPDFs || []} onChangeImage={setJobPDFs} canRemove={canDeleteImage} />
    </AppPaperModal>
  );
};

export default EditPDFAttachmentsModal;
